<button mat-icon-button
        class="burger-button"
        (click)="toggleMenu()"
        [class.active]="isMenuVisible"
        aria-label="Меню">
  <span *ngIf="!isMenuVisible"
        class="fa fal fa-bars">
        <span *ngIf="favsCounter !== '0'"
              class="burger-button__update-marker"
        ></span>
  </span>
  <span *ngIf="isMenuVisible"
    class="fa fal fa-times"></span>
  <!-- TODO: use MatIconRegistry to use icon set -->
</button>

<div class="header__menu"
     (click)="toggleMenu()"
     *ngIf="isMenuVisible">
  <div appSwipeHorizontal
       (OnSwipeRight)="toggleMenu()"
       class="menu__panel">
    <div class="panel__header">
      <ul class="panel__links">
        <li class="panel__link">
          <a [routerLink]="'/favorites'"
             routerLinkActive="__current"
          >
            <span class="icon fa far fa-star"></span>
            <span
              [matBadge]="favsCounter"
              [matBadgeHidden]="favsCounter === '0'"
              matBadgeColor="accent"
              matBadgeOverlap="false"
            >{{ 'header_menu_favourites' | translate }}</span>
          </a>
        </li>
      </ul>
    </div>

    <ul class="panel__links">
      <li class="panel__link">
        <a [routerLink]="'/genes'"
           routerLinkActive="__current">
          <span class="icon fa far fa-dna"></span>
          {{ "toggler_genes" | translate }}</a>
      </li>

      <li
        class="panel__link">
        <a [routerLink]="'/annotations'"
           routerLinkActive="__current"
        >
          <app-icon class="icon" icon="gene-ontology"></app-icon>
          {{ "toggler_annotations" | translate }}</a>
      </li>

      <li class="panel__link">
        <a [routerLink]="'/about'"
           routerLinkActive="__current"
        >
          <span class="icon fa far fa-question-circle"></span>
          {{ 'header_menu_help' | translate }}</a>
      </li>

      <li class="panel__link">
        <a [routerLink]="'/download'"
           routerLinkActive="__current">
          <span class="icon fa far fa-download">
          </span> {{ 'header_menu_download' | translate }}
        </a>
      </li>

      <li class="panel__separator"></li>

      <li
        class="panel__link">
        <a [routerLink]="'/datasets'"
           routerLinkActive="__current">
          <span class="icon fa far fa-database"></span>
          {{ "header_menu_datasets" | translate }}
        </a>
      </li>

      <li class="panel__link">
        <a href="/api/docs"
           target="_blank">
          <span class="icon fa far fa-terminal">
          </span> {{ 'header_menu_api' | translate }}&nbsp;<span class="fa far fa-external-link"></span>
        </a>
      </li>

      <li class="panel__link">
        <a [routerLink]="'/settings'"
           routerLinkActive="__current"
        >
          <span class="icon fa far fa-cog"></span>
          {{ 'header_menu_settings' | translate }}</a>
      </li>

      <li class="panel__link">
        <a [routerLink]="'/contributors'"
           routerLinkActive="__current"
        >
          <span class="icon fa far fa-users"></span>
          {{ 'header_menu_contributors' | translate }}</a>
      </li>

      <li class="panel__link">
        <a [routerLink]="'/timeline'"
           routerLinkActive="__current"
        >
          <span class="icon fa far fa-clock"></span>
          {{ 'header_menu_timeline' | translate }}</a>
      </li>
    </ul>

    <div class="panel__footer">
      <p>
        DOI:&nbsp;<a href="https://doi.org/10.1093/nar/gkad712"
                     class="link link--underline"
                     target="_blank">10.1093/nar/gkad712 <span class="fa far fa-external-link"></span></a>
      </p>
    </div>
  </div>
</div>

