<app-header [region]="region"
            [class.app-header--fixed]="isScrolled"
></app-header>
<router-outlet></router-outlet>
<app-footer
  *ngIf="!isErrorPage"
  [appData]="app">
  <div [innerHTML]="footerContent"></div>
</app-footer>

<ng-container *ngIf="showCookieBanner">
  <app-banner>
    <ng-container text>
        {{ "cookie_banner_text" | translate }}
      <a routerLink="/about/articles/cookies-policy"
         target="_blank"
      >{{ "cookie_banner_link" | translate }}</a>
    </ng-container>
    <ng-container button>
      <button
        mat-flat-button
        color="primary"
        (click)="acceptCookies()">
        {{ "cookie_banner_button" | translate }}
      </button>
    </ng-container>
  </app-banner>
</ng-container>
