export const environment = {
  name: 'dev',
  production: false,
  apiUrl: 'https://test.open-genes.com',
  searchApiUrl: 'https://publications-search-api-2yf55.ondigitalocean.app/',
  pubmedUrl: 'https://www.ncbi.nlm.nih.gov/pubmed/',
  proteinAtlasUrl: 'https://www.proteinatlas.org/',
  openLongevity80LevelCMS: 'https://openlongevity.com/',
  wordpressApiUrl: 'https://content.open-genes.com/wp-json/wp/v2/',
  debugMode: false,
  languages: ['ru', 'en', 'zh', 'es', 'cs', 'uk', 'pt'],
  mockJsonUrl: ['assets/data/articles-page-1.mock.json', 'assets/data/articles-page-2.mock.json'],
  termsJsonUrl: ['assets/i18n/terms-en.json', 'assets/i18n/terms-ru.json'],
  gaTrackingCode: 'G-825YYNSJFC',
  version: '1.2.1',
  build: '14233934874.957',
};
